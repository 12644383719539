'use strict';

export function ObserveAnimation() {
  // アニメーション対象
  const targetElements = document.querySelectorAll(
    ".js-observe-animation-target"
  );

  const observeAnimation = () => {
    if (!targetElements.length) return;

    const handleObserve = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          activateAnimation(entry.target);
        }
      });
    };

    const activateAnimation = (target) => {
      target.setAttribute("data-animated", "true");
    };

    const observerOptions = {
      threshold: [0.5]
    };

    const observer = new IntersectionObserver(handleObserve, observerOptions);

    targetElements.forEach((target) => {
      observer.observe(target);
    });
  };

  document.addEventListener("DOMContentLoaded", () => {
    if (targetElements.length) {
      targetElements.forEach((el) => {
        el.setAttribute("data-animated", "false");
      });
    }
  });

  window.onload = () => {
    observeAnimation();
  };

}